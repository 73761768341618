<template>
  <div class="px-1 pb-2">
    <v-data-table
      :headers="Headers"
      :items="members"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-col cols="12" class="mb-0">
            <v-toolbar flat>
              <v-toolbar-title>BookClub Members</v-toolbar-title>
            </v-toolbar>
          </v-col>

          <v-col cols="12" md="6" class="mb-5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { mapState } from "vuex";
import { snackMixin } from "@/mixins";
export default {
  name: "Members",
  mixins: [snackMixin],
  data() {
    return {
      expanded: [],
      search: "",
      panel: [0, 1, 2],
      disabled: false,
      Headers: [
        { text: "Name", value: "fullname" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phoneno" },
        { text: "Date", value: "created_on" },
        // { text: "Actions", value: "actions" },
      ],
      members: [],
    };
  },
  computed: {
    ...mapState(["curUser"]),
  },
  created() {
    apiClient
      .get("/bookclub_members?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.members = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message));
  },
  methods: {},
};
</script>
